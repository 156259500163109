exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-build-your-mvp-tsx": () => import("./../../../src/pages/build-your-mvp.tsx" /* webpackChunkName: "component---src-pages-build-your-mvp-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cloud-acceleration-tsx": () => import("./../../../src/pages/cloud-acceleration.tsx" /* webpackChunkName: "component---src-pages-cloud-acceleration-tsx" */),
  "component---src-pages-cloud-engineering-tsx": () => import("./../../../src/pages/cloud-engineering.tsx" /* webpackChunkName: "component---src-pages-cloud-engineering-tsx" */),
  "component---src-pages-cloud-strategy-and-assessment-tsx": () => import("./../../../src/pages/cloud-strategy-and-assessment.tsx" /* webpackChunkName: "component---src-pages-cloud-strategy-and-assessment-tsx" */),
  "component---src-pages-cloud-support-tsx": () => import("./../../../src/pages/cloud-support.tsx" /* webpackChunkName: "component---src-pages-cloud-support-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-containers-and-kubernetes-tsx": () => import("./../../../src/pages/containers-and-kubernetes.tsx" /* webpackChunkName: "component---src-pages-containers-and-kubernetes-tsx" */),
  "component---src-pages-ecommerce-tsx": () => import("./../../../src/pages/ecommerce.tsx" /* webpackChunkName: "component---src-pages-ecommerce-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-magento-development-tsx": () => import("./../../../src/pages/magento-development.tsx" /* webpackChunkName: "component---src-pages-magento-development-tsx" */),
  "component---src-pages-magento-maintenance-tsx": () => import("./../../../src/pages/magento-maintenance.tsx" /* webpackChunkName: "component---src-pages-magento-maintenance-tsx" */),
  "component---src-pages-monitoring-and-observability-tsx": () => import("./../../../src/pages/monitoring-and-observability.tsx" /* webpackChunkName: "component---src-pages-monitoring-and-observability-tsx" */),
  "component---src-pages-our-process-tsx": () => import("./../../../src/pages/our-process.tsx" /* webpackChunkName: "component---src-pages-our-process-tsx" */),
  "component---src-pages-our-work-automotive-pwa-adobe-commerce-tsx": () => import("./../../../src/pages/our-work/automotive-pwa-adobe-commerce.tsx" /* webpackChunkName: "component---src-pages-our-work-automotive-pwa-adobe-commerce-tsx" */),
  "component---src-pages-our-work-hardware-retailer-magento-upgrade-tsx": () => import("./../../../src/pages/our-work/hardware-retailer-magento-upgrade.tsx" /* webpackChunkName: "component---src-pages-our-work-hardware-retailer-magento-upgrade-tsx" */),
  "component---src-pages-our-work-healthcare-shopify-plus-optimization-tsx": () => import("./../../../src/pages/our-work/healthcare-shopify-plus-optimization.tsx" /* webpackChunkName: "component---src-pages-our-work-healthcare-shopify-plus-optimization-tsx" */),
  "component---src-pages-our-work-index-tsx": () => import("./../../../src/pages/our-work/index.tsx" /* webpackChunkName: "component---src-pages-our-work-index-tsx" */),
  "component---src-pages-our-work-sfcc-rapid-mvp-retail-tsx": () => import("./../../../src/pages/our-work/sfcc-rapid-mvp-retail.tsx" /* webpackChunkName: "component---src-pages-our-work-sfcc-rapid-mvp-retail-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-technologies-bigcommerce-tsx": () => import("./../../../src/pages/technologies/bigcommerce.tsx" /* webpackChunkName: "component---src-pages-technologies-bigcommerce-tsx" */),
  "component---src-pages-technologies-magento-tsx": () => import("./../../../src/pages/technologies/magento.tsx" /* webpackChunkName: "component---src-pages-technologies-magento-tsx" */),
  "component---src-pages-technologies-salesforce-commerce-cloud-tsx": () => import("./../../../src/pages/technologies/salesforce-commerce-cloud.tsx" /* webpackChunkName: "component---src-pages-technologies-salesforce-commerce-cloud-tsx" */),
  "component---src-pages-technologies-shopify-tsx": () => import("./../../../src/pages/technologies/shopify.tsx" /* webpackChunkName: "component---src-pages-technologies-shopify-tsx" */),
  "component---src-pages-technologies-woocommerce-wordpress-tsx": () => import("./../../../src/pages/technologies/woocommerce-wordpress.tsx" /* webpackChunkName: "component---src-pages-technologies-woocommerce-wordpress-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-ui-ux-tsx": () => import("./../../../src/pages/ui-ux.tsx" /* webpackChunkName: "component---src-pages-ui-ux-tsx" */),
  "component---src-pages-your-cloud-partner-tsx": () => import("./../../../src/pages/your-cloud-partner.tsx" /* webpackChunkName: "component---src-pages-your-cloud-partner-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-listing-tsx": () => import("./../../../src/templates/blog/listing.tsx" /* webpackChunkName: "component---src-templates-blog-listing-tsx" */)
}

